<template>
    <b-card title="Return To Store">
        <b-row>
            <b-col md="4">
                <b-form-group label="Order Item Barcode">
                    <b-form-input v-model="orderItemPeiceBarcode" placeholder="Please Enter Order Item Peice Barcode"></b-form-input>
                </b-form-group>
            </b-col>
            <b-col md="4">
                <b-button class="mt-2" @click="addByBarcode">Add</b-button>
            </b-col>

            <b-col md="12">
                <div>
                    <!-- search input -->
                    <div class="custom-search d-flex justify-content-end">
                        <b-col md="4">
                            
                        </b-col>

                        <b-form-group>
                            <div class="d-flex align-items-center">
                                <label class="mr-1">Search</label>
                                <b-form-input v-model="searchTerm" placeholder="Search" type="text"
                                    class="d-inline-block" />
                            </div>
                        </b-form-group>
                    </div>

                    <!-- table -->
                    <vue-good-table @on-selected-rows-change="selectionChanged" :line-numbers="true" :columns="columns"
                        :rows="rows" :rtl="direction" :search-options="{
                            enabled: true,
                            externalQuery: searchTerm
                        }" :select-options="{
    enabled: true,
    selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
    selectionInfoClass: 'custom-class',
    selectionText: 'rows selected',
    clearSelectionText: 'clear',
    disableSelectInfo: true, // disable the select info panel on top
    selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
}" :pagination-options="{
    enabled: true,
    perPage: pageLength
}">
                        <template slot="table-row" slot-scope="props">

                            <!-- Column: bookingDate -->
                            <span v-if="props.column.field === 'itemName'" class="text-nowrap">
                                <span class="text-nowrap">
                                    {{ props.row.itemName }}
                                </span><br>
                                <span style="font-size:12px" class="text-nowrap">
                                    {{ "Booking Date: "+convertTimeStampToDateLocal(props.row.bookingDate) }}
                                </span><br>
                                <span style="font-size:12px" class="text-nowrap">
                                    {{ "Due Date: "+convertTimeStampToDate(props.row.dueDate) }}
                                </span>
                            </span>

                            <!-- Column: customerName -->
                            <span v-else-if="props.column.field === 'customerName'" class="text-nowrap">
                                <span class="text-nowrap">
                                    {{ props.row.customerName }}
                                </span><br>
                                <span class="text-nowrap">
                                    {{ '['+props.row.customerMobileNo + ']' }}
                                </span><br>
                                <span class="text-nowrap">
                                    {{ props.row.address }}
                                </span>
                            </span>

                            <!-- Column: customerName -->
                            <span v-else-if="props.column.field === 'customerName'" class="text-nowrap">
                                <span class="text-nowrap">
                                    {{ props.row.customerName }}
                                </span>
                                <span class="text-nowrap">
                                    {{ '['+props.row.customerMobileNo + ']' }}
                                </span>
                                <span class="text-nowrap">
                                    {{ props.row.address }}
                                </span>
                            </span>

                            <span v-else-if="props.column.field === 'returnCause'" class="text-nowrap">
                                <v-select :options="returnCauseListOptions" placeholder="Select return Cause"
                                    label="name" v-model=props.row.returnCause></v-select>
                                <!-- {{ props.row.returnCause = props.row.returnCause }} -->



                            </span>

                            <!-- Column: Common -->
                            <span v-else>
                                {{ props.formattedRow[props.column.field] }}
                            </span>
                        </template>

                        <!-- pagination -->
                        <template slot="pagination-bottom" slot-scope="props">
                            <div class="d-flex justify-content-between flex-wrap">
                                <div class="d-flex align-items-center mb-0 mt-1">
                                    <span class="text-nowrap ">
                                        Showing 1 to
                                    </span>
                                    <b-form-select v-model="pageLength" :options="['10', '20', '50', '100']"
                                        class="mx-1"
                                        @input="(value) => props.perPageChanged({ currentPerPage: value })" />
                                    <span class="text-nowrap"> of {{ props.total }} entries </span>
                                </div>
                                <div>
                                    <b-pagination :value="1" :total-rows="props.total" :per-page="pageLength"
                                        first-number last-number align="right" prev-class="prev-item"
                                        next-class="next-item" class="mt-1 mb-0"
                                        @input="(value) => props.pageChanged({ currentPage: value })">
                                        <template #prev-text>
                                            <feather-icon icon="ChevronLeftIcon" size="18" />
                                        </template>
                                        <template #next-text>
                                            <feather-icon icon="ChevronRightIcon" size="18" />
                                        </template>
                                    </b-pagination>
                                </div>
                            </div>
                        </template>
                    </vue-good-table>
                </div>
            </b-col>
        </b-row>
        <hr>
        <b-row>
            <b-col class="text-center">
                <b-button @click="updateOrderItemReturnStatus">Return Garments</b-button>
            </b-col>
        </b-row>
    </b-card>
</template>
<script>
import {
    BCard, BCol, BRow, BPagination, BFormSelect, BFormGroup, BFormInput, BButton
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import api from '@/store/api'
import moment from 'moment'
import vSelect from 'vue-select'

export default {
    components: {
        BCard, BCol, BRow, VueGoodTable, BPagination, BFormSelect, BFormGroup, BFormInput, BButton,
        vSelect
    },
    data() {
        return {
            orderId: 0,
            pageLength: 10,
            dir: false,
            columns: [
                {
                    label: 'Order No',
                    field: 'orderNo'
                },
                {
                    label: 'Qr Code',
                    field: 'qrCode',
                },
                {
                    label: 'Customer Details',
                    field: 'customerName'
                },
                {
                    label: 'ItemName',
                    field: 'itemName',
                },
                {
                    label: 'Garment Status',
                    field: 'garmentProcessStatus'
                },
                {
                    label: 'Services',
                    field: 'service.name',
                },
                {
                    label: 'Return Cause',
                    field: 'returnCause',
                    width: '300px'
                },
                // {
                //     label: 'Brands',
                //     field: 'brands'
                // },
                // {
                //     label: 'Colors',
                //     field: 'colors'
                // },
                // {
                //     label: 'Garment Remarks',
                //     field: 'garmentRemarks'
                // },
                // {
                //     label: 'TopUpServices',
                //     field: 'topUpServices',
                // },
            ],
            rows: [],
            searchTerm: '',
            orderItemPieceList: [],
            returnCauseListOptions: [],
            returnCauseMain: '',
            orderItemPeiceBarcode:''

        }
    },
    props: {
        getOrderDetail: Function
    },
    methods: {

        convertTimeStampToDate(value) {
            if (value) {
                return moment.utc(value).format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        convertTimeStampToDateLocal(value) {
            if (value) {
                return moment.utc(value).local().format('DD/MM/YYYY hh:mm A');
            } else {
                return '';
            }
        },

        getOrderItemReturnList(orderItemIdList, orderId) {
            this.orderId = orderId;
            let self = this;
            var axios = require('axios');
            var data = orderItemIdList;
            axios(api.getApi('POST', '/orders/getOrderItemPicesListToReturn/' + orderId, data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    self.rows = response.data;
                    // for (let index = 0; index < self.rows.length; index++) {
                    //     self.rows[index].returnCause = '';

                    // }

                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        selectionChanged(params) {
            this.orderItemPieceList = params.selectedRows;
        },

        updateOrderItemReturnStatus() {
            if (this.returnCauseMain != null) {
                if(this.orderItemPieceList.length>0){
                const orderReturnItemsList = [];
                let self = this;
                for (let index = 0; index < this.orderItemPieceList.length; index++) {
                    orderReturnItemsList.push({
                        "garmentReturnCause": this.orderItemPieceList[index].returnCause.name,
                        "orderItemPiceId": this.orderItemPieceList[index].id,
                        "returnCauseByEmpId": self.$store.state.empId
                    })
                }
                var axios = require('axios');
                var data = JSON.stringify(orderReturnItemsList);
                axios(api.getApi('PUT', '/orders/updateOrderItemPicesReturnCause/' + self.orderId, data))
                    .then(function (response) {
                        // console.log(JSON.stringify(response.data));
                        self.$swal({
                        title: '',
                        text: "Order Items Return Successfully.",
                        icon: 'success',
                        customClass: {
                            confirmButton: 'btn btn-primary',
                        },
                        buttonsStyling: false,
                    })
                        self.getOrderDetail(self.orderId);
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
                }else{
                    this.$swal({
                    title: '',
                    text: "Please Select Items Pieces.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
                }
            } else {
                this.$swal({
                    title: '',
                    text: "Please Select Return Cause.",
                    icon: 'error',
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            }


        },
        addByBarcode() {
            let self = this;
            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/orders/getOrderItemPicesToReturn/' + self.orderId + ',' + self.orderItemPeiceBarcode, data))
                .then(function (response) {
                    // console.log(JSON.stringify(response.data));
                    self.rows.push(response.data);
                })
                .catch(function (error) {
                    console.log(error);
                });
        },
        getGarmentReturnCause() {
            let self = this;

            var axios = require('axios');
            var data = '';
            axios(api.getApi('get', '/masters/garmentReturnCause', data))
                .then(function (response) {
                    //console.log(JSON.stringify(response.data));
                    self.returnCauseListOptions = response.data;
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

    },
    created() {
        this.getGarmentReturnCause();
    }
}
</script>
<style lang="css">
@import '~vue-good-table/dist/vue-good-table.css';
</style>
